<template>
  <div class="billDetails">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">账单明细</div>
      <div class="rightBtn"></div>
    </div>
    <div class="detailMain">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="detailItem" v-for="item in list" :key="item.id">
          <div class="detailInfo">
            <div class="detailTitle">{{ item.tranType }}</div>
            <div class="timer">{{ item.createdAt | time }}</div>
          </div>
          <div class="contentBox">
            <div class="detailDesc">{{ item.desc }}</div>
            <div class="gold" v-if="$route.query.t == 'game'">
              <svg-icon class="addIcon" iconClass="bill_reduce" v-if="item.money < 0"></svg-icon>
              <svg-icon class="addIcon" iconClass="bill_add" v-else></svg-icon>
              {{ item.money > 0 ? item.money : -item.money }}游戏币
            </div>
            <div class="gold" v-else>
              <svg-icon class="addIcon" iconClass="bill_reduce" v-if="item.actualAmount < 0"></svg-icon>
              <svg-icon class="addIcon" iconClass="bill_add" v-else></svg-icon>
              {{ item.actualAmount > 0 ? item.actualAmount : -item.actualAmount }}金币
            </div>
          </div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
import { billDetail } from '@/api/mine';
import { gameBill } from '@/api/game';
import PullRefresh from '@/components/PullRefresh';
// import ImgDecypt from "@/components/ImgDecypt";
export default {
  name: 'BillDetails',
  components: {
    // ImgDecypt,
    PullRefresh,
  },
  data() {
    return {
      list: [], // 列表
      pageNumber: 1, // 页码
      pageSize: 10, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
    };
  },
  created() {
    this.$route.query.t == 'game' ? this.queryGameList() : this.getList();
  },
  methods: {
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      try {
        let res = await this.$Api(billDetail, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },

    // 查询游戏账单列表
    async queryGameList(type) {
      let req = {
        pageNum: this.pageNumber,
        pageSize: this.pageSize,
      };
      try {
        let res = await this.$Api(gameBill, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },

    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.$route.query.t == 'game' ? this.queryGameList() : this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.$route.query.t == 'game' ? this.queryGameList('refresh') : this.getList('refresh');
    },
  },
};
</script>

<style lang="scss" scoped>
.billDetails {
  height: 100%;
  box-sizing: border-box;

  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .detailMain {
    padding: 0 12px;
    height: calc(100% - 44px);
    // overflow-y: auto;

    .detailItem {
      background-color: #fff;
      padding: 20px 12px;
      margin-top: 10px;
      border-radius: 6px;
      .detailInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 11px;
        .detailTitle {
          font-size: 17px;
          font-weight: 600;
        }

        .avatarImg {
          width: 43px;
          height: 43px;
        }

        .detailOpt {
          flex: 2;
          margin: 0 10px;

          span {
            opacity: 0.6;
          }
        }

        .timer {
          font-size: 14px;
          color: rgb(102, 102, 102);
        }
      }

      .contentBox {
        display: flex;
        justify-content: space-between;

        .detailDesc {
          width: 200px;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: nowrap;
          font-size: 16px;
        }

        .gold {
          display: flex;
          align-items: center;
          color: rgb(255, 103, 143);
          font-size: 20px;

          .addIcon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
</style>
